<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    <template v-if="isCreation">
                        Créer une disponibilité
                    </template>
                    <template v-else>
                        Gérer une disponibilité
                    </template>
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <p>
                    Créez ou modifiez ici une disponibilité pour le ou les intervenants concernés le <span class="font-weight-bold"> {{ day | toDate() }} </span>.
                </p>

                <ValidationObserver ref="observer">
                    <table style="border-spacing: 10px; width: 100%">
                        <tr>
                            <td>
                                Quel(s) intervenant(s)
                            </td>

                            <td>
                                <AppointmentStakeholderAutocomplete ref="appointmentStakeholderAutocomplete" v-model="disponibility.stakeholderIds" :disabled="!isCreation" multiple noDynamicSearch />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span>
                                    Disponibilité
                                </span>
                            </td>

                            <td>
                                <ValidationProvider v-slot="{ errors, failed }" name="De" rules="required">
                                    <HoursSelect v-model="startDateHours" label="De" :errors="errors" :failed="failed" />
                                </ValidationProvider>
                            </td>

                            <td>
                                <ValidationProvider v-slot="{ errors, failed }" name="A" rules="required">
                                    <HoursSelect v-model="endDateHours" label="A" :errors="errors" :failed="failed" />
                                </ValidationProvider>
                            </td>
                        </tr>
                    </table>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" class="mr-2" color="primary" small> Enregistrer </v-btn>

                <ConfirmationDialog v-if="!isCreation" @confirm="remove()" text="Voulez-vous vraiment supprimer cette disponibilité">
                    <template v-slot:activator="{ on: dialog }">
                        <v-btn color="error" small v-on="dialog"> Supprimer </v-btn>
                    </template>
                </ConfirmationDialog>
                
                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import HoursSelect from '../widgets/HoursSelect.vue';
import ConfirmationDialog from '../dialogs/ConfirmationDialog.vue';
import AppointmentStakeholderAutocomplete from '../widgets/AppointmentStakeholderAutocomplete.vue';
import { addHours, addMinutes, format, startOfDay } from 'date-fns';

export default {
    name: 'CreateDisponibilityDialog',

    components: {
        HoursSelect,
        ConfirmationDialog,
        AppointmentStakeholderAutocomplete
    },

    data: () => ({
        day: new Date(),

        isOpen: false,
        disponibility: {},
        additionalDataFetched: false,

        startDateHours: null,
        endDateHours: null
    }),

    computed: {
        isCreation() {
            return !this.disponibility || !this.disponibility.id;
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.$nextTick(() => {
                    if (!this.additionalDataFetched) {
                        this.additionalDataFetched = true;
                        this.$refs.appointmentStakeholderAutocomplete.fetchStakeholders({ limit: 10000 });
                    }
                    this.$refs.observer.reset();
                });
            }
        }
    },

    methods: {
        open(disponibility, stakeholderIds, day) {
            if (day) {
                this.day = startOfDay(new Date(day));
            } else {
                this.day = new Date();
            }

            this.disponibility = {};
            this.startDateHours = null;
            this.endDateHours = null;
            if (disponibility) {
                this.disponibility = disponibility;
                if (this.disponibility.stakeholder) {
                    this.disponibility.stakeholderIds = [this.disponibility.stakeholder.id];
                }
            }
            if (stakeholderIds) {
                this.disponibility.stakeholderIds = stakeholderIds;
            }

            if (this.disponibility.startDate) {
                this.startDateHours = format(new Date(this.disponibility.startDate), 'HH:mm');
                this.day = startOfDay(new Date(this.disponibility.startDate));
            }

            if (this.disponibility.endDate) {
                this.endDateHours = format(new Date(this.disponibility.endDate), 'HH:mm');
            }

            this.isOpen = true;
        },

        async submitCreation() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = { disponibility: this.disponibility };

                const { success, err } = await this.repos.appointments.createDisponibility(body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('created');
                    this.$notify({
                        title: 'Information',
                        text: 'La disponibilité a bien été créée',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la modification d\'une disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async submitEdition() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = { disponibility: this.disponibility };

                const { success, err } = await this.repos.appointments.updateDisponibility(this.disponibility.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated');
                    this.$notify({
                        title: 'Information',
                        text: 'La disponibilité a bien été mis à jour',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création d\'une disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async remove() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const { success, err } = await this.repos.appointments.deleteDisponibility(this.disponibility.id);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated');
                    this.$emit('removed');
                    this.$notify({
                        title: 'Information',
                        text: 'La disponibilité a bien été supprimée',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression de la disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            let [hours, minutes] = this.startDateHours.split(':');
            hours = parseInt(hours);
            minutes = parseInt(minutes);
            let startDate = this.day;

            startDate = addHours(startDate, hours);
            startDate = addMinutes(startDate, minutes);

            [hours, minutes] = this.endDateHours.split(':');
            hours = parseInt(hours);
            minutes = parseInt(minutes);
            let endDate = this.day;

            endDate = addHours(endDate, hours);
            endDate = addMinutes(endDate, minutes);

            this.disponibility.startDate = startDate;
            this.disponibility.endDate = endDate;

            if (this.isCreation) {
                await this.submitCreation();
            } else {
                await this.submitEdition();
            }
        }
    }
};
</script>
