<template>
    <v-autocomplete ref="autocomplete" v-model="selectedHours" :items="hoursItems" :disabled="disabled" item-text="text" @change="change($event)" :filter="customSearch" :menu-props="{ bottom: true, offsetY: true }" :label="label" :placeholder="placeholder" color="app-blue" background-color="white" :error-messages="errors" :hide-details="!failed" outlined dense>

    </v-autocomplete>
</template>

<script>
import { addMinutes, format, startOfDay } from 'date-fns';
// import { addHours, addMinutes, format, getHours, getMinutes, startOfDay } from 'date-fns';

export default {
    name: 'HoursSelect',

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        errors: { type: Array },
        failed: { type: Boolean, default: false }
    },

    data: () => ({
        selectedHours: null,
        hoursItems: [],
        search: null
    }),

    watch: {
        value() {
            if (this.value) {
                this.selectedHours = this.value;
            } else {
                this.$refs.autocomplete.reset();
            }
        }
    },

    methods: {
        customSearch(item, queryText, itemText) {
            const [hour] = itemText.split(':');
            return hour.includes(queryText);
        },

        change(value) {
            this.$emit('input', value);
        },

        initHours() {
            const date = startOfDay(new Date());

            this.$nextTick(function () {
                if (this.value) {
                    this.selectedHours = this.value;
                } else {
                    this.$refs.autocomplete.reset();
                }
            });

            const increment = 15;
            const minutesInADay = 1425;
            for (let minutes = 0; minutes <= minutesInADay; minutes += increment) {
                const newDate = addMinutes(date, minutes);
                const hours = format(newDate, 'HH:mm');
                this.hoursItems.push(hours);
            }
        }
    },

    created() {
        this.initHours();
    }
};
</script>