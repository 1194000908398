<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Événements du {{ day | toDate(false) }}
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                            <tr v-for="(event, i) of appointments" :key="i + '_appointmentitem'">
                                <td style="width: 1%;">
                                    <v-chip :color="event.color" dark label>
                                        Rendez-vous
                                    </v-chip>
                                </td>

                                <td>
                                    {{ event.data.appointment.subject }}
                                </td>

                                <td>
                                    de <span class="black--text"> {{ event.data.appointment.instance.startDate | toTime(false) }} </span>
                                    à <span class="black--text"> {{ event.data.appointment.instance.endDate | toTime(false) }} </span>
                                </td>

                                <td></td>
                            </tr>

                            <tr v-for="(event, i) of disponibilities" :key="i + '_disponibilityitem'">
                                <td style="width: 1%;">
                                    <v-chip :color="event.color" dark label>
                                        Disponibilité
                                    </v-chip>
                                </td>

                                <td>
                                    {{ event.data.disponibility.stakeholder.fullname }}
                                </td>

                                <td>
                                    de <span class="black--text"> {{ event.data.disponibility.startDate | toTime(false) }} </span>
                                    à <span class="black--text"> {{ event.data.disponibility.endDate | toTime(false) }} </span>
                                </td>

                                <td>
                                    <v-btn color="primary" icon small @click="$refs.createDisponibilityDialog.open(event.data.disponibility)">
                                        <v-icon small> fas fa-edit </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" small> Fermer </v-btn>
            </v-card-actions>
        </v-card>

        <CreateDisponibilityDialog ref="createDisponibilityDialog" @updated="isUpdated = true" @removed="isOpen = false" />
    </v-dialog>
</template>

<script>
import CreateDisponibilityDialog from './CreateDisponibilityDialog.vue';

export default {
    name: 'MoreEventsDialog',

    components: {
        CreateDisponibilityDialog
    },

    data: () => ({
        isOpen: false,
        day: null,
        isUpdated: false,
        events: []
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.isUpdated = false;
            } else {
                if (this.isUpdated) {
                    this.$emit('updated');
                }
            }
        }
    },

    computed: {
        appointments() {
            return this.events.filter((e) => e.data.appointment);
        },

        disponibilities() {
            return this.events.filter((e) => e.data.disponibility);
        }
    },

    methods: {
        open(day, events) {
            this.day =day;
            this.events = events;
            this.isOpen = true;
        }
    }
};
</script>
