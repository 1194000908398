<template>
    <v-dialog v-model="isOpen" width="600px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    {{ dialogTitle }}
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <p>
                    Décrivez ici un intervenant, ses compétences et les programmes le concernant dans sa mission d'accompagnement des acquéreurs.
                </p>

                <ValidationObserver ref="observer">
                    <table style="border-spacing: 10px; width: 100%">
                        <!-- Utilisateur -->
                        <tr>
                            <td>
                                Utilisateur
                            </td>

                            <td>
                                <ValidationProvider v-slot="{ errors, failed }" name="Utilisateur" rules="required">
                                    <UserAutocomplete v-model="stakeholder.userid" @selected="onUserSelected" :errors="errors" :failed="failed" hint="Quel est l'utilisateur Axessia associé à cet intervenant?" />
                                </ValidationProvider>
                            </td>
                        </tr>

                        <!-- Nom et Photo -->
                        <tr>
                            <!-- Nom -->
                            <td>
                                Nom
                            </td>

                            <td>
                                <ValidationProvider v-slot="{ errors, failed }" name="Utilisateur" rules="required">
                                    <v-text-field v-model="stakeholder.fullname" dense outlined :error-messages="errors" :hide-details="!failed" />
                                </ValidationProvider>
                            </td>
                        </tr>

                        <!-- Compétences -->
                        <tr>
                            <td>
                                Compétences
                            </td>

                            <td>
                                <AppointmentCategoryAutocomplete ref="appointmentCategoryAutocomplete" v-model="stakeholder.categoryIds" hint="Dans quel(s) type(s) de pièces cet ensemble de choix peut-il être proposé?" noDynamicSearch multiple />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Programme

                            </td>

                            <td>
                                <ValidationProvider v-slot="{ errors, failed }" name="Programme" rules="">
                                    <ProgramAutocomplete ref="programAutocomplete" v-model="stakeholder.programIds" :errors="errors" :failed="failed" noDynamicSearch multiple />
                                </ValidationProvider>
                            </td>
                        </tr>
                    </table>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import UserAutocomplete from '../widgets/UserAutocomplete.vue';
import ProgramAutocomplete from '../widgets/ProgramAutocomplete.vue';
import AppointmentCategoryAutocomplete from '../widgets/AppointmentCategoryAutocomplete.vue';

export default {
    name: 'ManageStakeholderDialog',

    components: {
        UserAutocomplete,
        ProgramAutocomplete,
        AppointmentCategoryAutocomplete
    },

    props: {
        initStakeholder: { type: Object }
    },

    data: () => ({
        isOpen: false,
        stakeholder: {}
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.stakeholder = this.initStakeholder ? JSON.parse(JSON.stringify(this.initStakeholder)) : {};
                this.$nextTick(() => {
                    this.$refs.appointmentCategoryAutocomplete.fetchCategories({ limit: 10000 });
                    this.$refs.programAutocomplete.fetchPrograms({ limit: 10000 });
                    this.$refs.observer.reset();
                });
            }
        }
    },

    computed: {
        isCreation() {
            return !this.initStakeholder;
        },

        dialogTitle() {
            if (this.isCreation) {
                return 'Création d\'un intervenant';
            } else {
                return 'Modification d\'un intervenant';
            }
        }
    },

    methods: {
        async submitCreation() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            if (!this.stakeholder.userid) {
                this.$notify({
                    title: 'Information',
                    text: 'La saisie de l\'utilisateur est obligatoire',
                    type: 'warning'
                });
                return;
            }

            try {
                this.setLoading(true);

                const body = { stakeholder: this.stakeholder };

                const { success, err } = await this.repos.appointments.createStakeholder(body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('created');
                    this.$notify({
                        title: 'Information',
                        text: 'L\'intervenant a été créé avec succès',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création d\'un nouvel intervenant',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async submitEdition() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            if (!this.stakeholder.userid) {
                this.$notify({
                    title: 'Information',
                    text: 'La saisie de l\'utilisateur est obligatoire',
                    type: 'warning'
                });
                return;
            }

            try {
                this.setLoading(true);

                const body = { stakeholder: this.stakeholder };

                const { success, err } = await this.repos.appointments.updateStakeholder(this.initStakeholder.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated');
                    this.$notify({
                        title: 'Information',
                        text: 'L\'intervenant a été modifié avec succès',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la modification d\'un nouvel intervenant',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async submit() {
            if (this.isCreation) {
                this.submitCreation();
            } else {
                this.submitEdition();
            }
        },

        onUserSelected(selectedUser) {
            if (selectedUser) {
                this.stakeholder.fullname = `${selectedUser.name || ''} ${selectedUser.firstname || ''}`.trim();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.asset-card {
    width: 160px;
    height: 160px;
}
</style>