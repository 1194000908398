<template>
    <v-dialog v-model="isOpen" width="1000px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Planning intervenants
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <p>
                    Affichez et gérez ici les rendez-vous et disponibilités des différents intervenants participant aux rendez-vous acquéreurs.
                </p>

                <div class="d-flex mb-4">
                    <div class="d-flex align-baseline">
                        <v-checkbox v-model="displayDisponibilities" label="Afficher les disponibilités" class="mr-4" hide-details dense />

                        <v-checkbox v-model="displayAppointments" label="Afficher les rendez-vous" hide-details dense />
                    </div>

                    <v-spacer />

                    <div class="d-flex align-center">
                        <span class="font-weight-bold mr-4">
                            {{ planningDate | toMonthYearDate()}}
                        </span>

                        <v-btn @click="backToToday()" class="mr-4" small depressed>
                            Aujourd'hui
                        </v-btn>

                        <v-btn icon small @click="$refs.calendar.prev()">
                            <v-icon> fas fa-chevron-left </v-icon>
                        </v-btn>

                        <v-select v-model="type" :items="types" class="flex-grow-0" style="max-width: 150px" outlined hide-details dense />

                        <v-btn icon small @click="$refs.calendar.next()">
                            <v-icon> fas fa-chevron-right </v-icon>
                        </v-btn>
                    </div>
                </div>

                <v-calendar v-model="planningDate" style="height: 450px" ref="calendar" :type="type" :events="events" :weekdays="[1, 2, 3, 4, 5, 6, 0]" @click:date="dateClick" @click:event="eventClick" @click:more="eventsClick" />
            </v-card-text>

            <v-card-actions>
                <div class="d-flex">
                    <AppointmentStakeholderAutocomplete v-model="selectedStakeholders" label="Intervenants" @input="updateEvents()" classes="flex-grow-0" ref="appointmentStakeholderAutocomplete" noDynamicSearch multiple />
                </div>
            </v-card-actions>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" small> Fermer </v-btn>
            </v-card-actions>

            <v-menu v-model="selectedOpen" :activator="selectedAppointmentBadge" :close-on-content-click="false" offset-y>
                <v-card v-if="selectedAppointment">
                    <v-card-title>
                        {{ selectedAppointment.subject }}
                    </v-card-title>

                    <v-card-text>
                        Le <span class="black--text"> {{ selectedAppointment.instance.startDate | toDate() }} </span>
                        de <span class="black--text"> {{ selectedAppointment.instance.startDate | toTime(false) }} </span>
                        à <span class="black--text"> {{ selectedAppointment.instance.endDate | toTime(false) }} </span>
                        <br>

                        Acquéreur:
                        <span class="black--text">
                            {{ selectedAppointment.instance.buyers[0].title }}
                            {{ selectedAppointment.instance.buyers[0].name }}
                            {{ selectedAppointment.instance.buyers[0].firstname }}
                        </span>

                        <br>
                        Lieux: <span class="black--text"> {{ selectedAppointment.location }} </span>
                    </v-card-text>
                </v-card>
            </v-menu>

            <CreateDisponibilityDialog ref="createDisponibilityDialog" @created="onDisponibilityUpdated()" @updated="onDisponibilityUpdated()" />
            <MoreEventsDialog ref="moreEventsDialog" @updated="onDisponibilityUpdated" />
        </v-card>
    </v-dialog>
</template>

<script>
import MoreEventsDialog from './MoreEventsDialog.vue';
import CreateDisponibilityDialog from './CreateDisponibilityDialog.vue';
import AppointmentStakeholderAutocomplete from '../widgets/AppointmentStakeholderAutocomplete.vue';

import { format, isSameDay, startOfMonth, endOfMonth } from 'date-fns';

export default {
    name: 'ManageStakeholderPlanningDialog',

    components: {
        MoreEventsDialog,
        CreateDisponibilityDialog,
        AppointmentStakeholderAutocomplete
    },

    props: {
        initStakeholderIds: { type: Array, default: () => ([]) },
        initDisplayAppointments: { type: Boolean, default: true },
        initDisplayDisponibilities: { type: Boolean, default: true }
    },

    data: () => ({
        isOpen: false,
        selectedOpen: null,
        selectedAppointmentBadge: null,
        selectedAppointment: null,
        planningDate: format(new Date(), 'yyyy-MM-dd'),
        type: 'month',
        types: [
            { text: 'Mois', value: 'month' },
            { text: 'Semaine', value: 'week' },
            { text: 'Jour', value: 'day' }
        ],
        selectedStakeholders: [],
        events: [],
        displayAppointments: true,
        appointments: [],
        displayDisponibilities: true,
        disponibilities: [],
        additionalDataFetched: false,
        hasUpdatedItems: false
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                if (this.initStakeholderIds.length > 0) {
                    this.selectedStakeholders = this.initStakeholderIds;
                }
                this.displayAppointments = this.initDisplayAppointments;
                this.displayDisponibilities = this.initDisplayDisponibilities;

                if (!this.additionalDataFetched) {
                    this.additionalDataFetched = true;
                    this.$nextTick(() => {
                        this.$refs.appointmentStakeholderAutocomplete.fetchStakeholders({ limit: 10000 });
                    });
                }
            } else {
                if (this.hasUpdatedItems) {
                    this.hasUpdatedItems = false;
                    this.$emit('updated');
                }
            }
        },

        selectedStakeholders() {
            this.fetchData();
        },

        planningDate() {
            this.fetchData();
        },

        displayAppointments() {
            if (this.displayAppointments) {
                this.fetchAppointments();
            } else {
                this.updateEvents();
            }
        },

        displayDisponibilities() {
            if (this.displayDisponibilities) {
                this.fetchDisponibilities();
            } else {
                this.updateEvents();
            }
        }
    },

    methods: {
        updateEvents() {
            this.events.splice(0, this.events.length);

            if (this.displayDisponibilities) {
                for (const disponibility of this.disponibilities) {
                    this.events.push({
                        name: disponibility.stakeholder.fullname,
                        start: new Date(disponibility.startDate),
                        end: new Date(disponibility.endDate),
                        color: 'grey',
                        timed: true,
                        data: {
                            disponibility
                        }
                    });
                }
            }

            if (this.displayAppointments) {
                for (const appointment of this.appointments) {
                    this.events.push({
                        name: appointment.subject,
                        start: new Date(appointment.instance.startDate),
                        end: new Date(appointment.instance.endDate),
                        color: 'green',
                        timed: true,
                        data: {
                            appointment: appointment
                        }
                    });
                }
            }
        },

        fetchData () {
            if (this.displayAppointments) {
                this.fetchAppointments();
            } else {
                this.appointments.splice(0, this.appointments.length);
            }

            if (this.displayDisponibilities) {
                this.fetchDisponibilities();
            } else {
                this.disponibilities.splice(0, this.disponibilities.length);
            }
            this.updateEvents();
        },

        async fetchDisponibilities() {
            try {
                this.setLoading(true);

                const date = new Date(this.planningDate);
                const start = format(startOfMonth(date), 'yyyy-MM-dd HH:mm:ss');
                const end = format(endOfMonth(date), 'yyyy-MM-dd HH:mm:ss');

                const query = {
                    disponibilityDate: `${start},${end}`
                };

                if (Array.isArray(this.selectedStakeholders) && this.selectedStakeholders.length > 0) {
                    query.stakeholders = this.selectedStakeholders;
                }

                const { disponibilities, err } = await this.repos.appointments.getDisponibilities(query);
                if (err) {
                    throw new Error(err);
                }
                this.disponibilities = disponibilities;
                this.updateEvents();
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchAppointments() {
            try {
                this.setLoading(true);

                const date = new Date(this.planningDate);
                const start = format(startOfMonth(date), 'yyyy-MM-dd HH:mm:ss');
                const end = format(endOfMonth(date), 'yyyy-MM-dd HH:mm:ss');

                const query = {
                    instanceDate: `${start},${end}`
                };

                if (Array.isArray(this.selectedStakeholders) && this.selectedStakeholders.length > 0) {
                    query.stakeholders = this.selectedStakeholders;
                }

                const { appointments, err } = await this.repos.appointments.getAppointments(query);
                if (err) {
                    throw new Error(err);
                }
                this.appointments = appointments;
                this.updateEvents();
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        onDisponibilityUpdated() {
            this.hasUpdatedItems = true;
            this.fetchDisponibilities();
        },

        dateClick(event) {
            this.$refs.createDisponibilityDialog.open(null, this.selectedStakeholders, event.date);
        },

        backToToday() {
            this.planningDate = format(new Date(), 'yyyy-MM-dd');
        },

        eventClick({ nativeEvent, event }) {
            if (event.data.appointment) {
                this.selectedOpen = false;
                this.selectedAppointment = event.data.appointment;
                this.selectedAppointmentBadge = nativeEvent.target;

                setTimeout(() => {
                    this.selectedOpen = true;
                }, 100);
            } else if (event.data.disponibility) {
                this.$refs.createDisponibilityDialog.open(event.data.disponibility);
            }
        },

        eventsClick(data) {
            const day = new Date(data.date);

            const events = [];
            for (const event of this.events) {
                if (isSameDay(day, event.start)) {
                    events.push(event);
                }
            }
            this.$refs.moreEventsDialog.open(day, events);
        }
    }
};
</script>