var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-text',[_c('p',[_vm._v(" Décrivez ici un intervenant, ses compétences et les programmes le concernant dans sa mission d'accompagnement des acquéreurs. ")]),_c('ValidationObserver',{ref:"observer"},[_c('table',{staticStyle:{"border-spacing":"10px","width":"100%"}},[_c('tr',[_c('td',[_vm._v(" Utilisateur ")]),_c('td',[_c('ValidationProvider',{attrs:{"name":"Utilisateur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('UserAutocomplete',{attrs:{"errors":errors,"failed":failed,"hint":"Quel est l'utilisateur Axessia associé à cet intervenant?"},on:{"selected":_vm.onUserSelected},model:{value:(_vm.stakeholder.userid),callback:function ($$v) {_vm.$set(_vm.stakeholder, "userid", $$v)},expression:"stakeholder.userid"}})]}}])})],1)]),_c('tr',[_c('td',[_vm._v(" Nom ")]),_c('td',[_c('ValidationProvider',{attrs:{"name":"Utilisateur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","error-messages":errors,"hide-details":!failed},model:{value:(_vm.stakeholder.fullname),callback:function ($$v) {_vm.$set(_vm.stakeholder, "fullname", $$v)},expression:"stakeholder.fullname"}})]}}])})],1)]),_c('tr',[_c('td',[_vm._v(" Compétences ")]),_c('td',[_c('AppointmentCategoryAutocomplete',{ref:"appointmentCategoryAutocomplete",attrs:{"hint":"Dans quel(s) type(s) de pièces cet ensemble de choix peut-il être proposé?","noDynamicSearch":"","multiple":""},model:{value:(_vm.stakeholder.categoryIds),callback:function ($$v) {_vm.$set(_vm.stakeholder, "categoryIds", $$v)},expression:"stakeholder.categoryIds"}})],1)]),_c('tr',[_c('td',[_vm._v(" Programme ")]),_c('td',[_c('ValidationProvider',{attrs:{"name":"Programme","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('ProgramAutocomplete',{ref:"programAutocomplete",attrs:{"errors":errors,"failed":failed,"noDynamicSearch":"","multiple":""},model:{value:(_vm.stakeholder.programIds),callback:function ($$v) {_vm.$set(_vm.stakeholder, "programIds", $$v)},expression:"stakeholder.programIds"}})]}}])})],1)])])])],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Enregistrer ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }